import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert'
import "./DismissibleAlert.css";



class DismissibleAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isActive: true,
        }
      }
    
      hideAlert() {
        this.setState({
          isActive: false,
        });
      }


      render() {
        if (this.state.isActive) {
          return (
            <Alert className="overlay" variant="dark" onClose={() => this.hideAlert()} dismissible>
            {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
            <p> {this.props.text}
            </p>
          </Alert>
            //   <div
            //     className="overlay alert alert-success alert-dismissible ">
            //     <button
            //       type="button"
            //       className="close"
            //       data-dismiss="alert"
            //       aria-label="Close"
            //       onClick={() => this.hideAlert()}
            //     >
            //       <span aria-hidden="true">&times;</span>
            //     </button>
            //     
            //   </div>
          );
        }
        return <div/>
      }
    }

export default DismissibleAlert;
