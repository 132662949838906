import React, {Component} from 'react';
import * as d3 from "d3";
import {Dropdown,Button,ButtonGroup,Modal} from 'react-bootstrap';
import "./D3GridPlot.css";

class D3GridPlot extends Component {
    constructor(props) {
        super(props);
            this.state={
                margin: {
                    top: 150,
                    bottom: 30,
                    left: 30,
                    right: 30
                }
            }
        this.drawChart = this.drawChart.bind(this)
        this.drawLegend = this.drawLegend.bind(this)
    }

    componentDidMount() {
        this.drawChart();
        this.drawLegend();
    }

    preprocessData(){
        // preprocess data
        var dataStatus = [];
        var xAxis = [];
        var yAxis = [];
        var dataStatusWhole = [];
        let data = require("./responseGridData.json");

        var dataKeys = Object.keys(data);

        for (var keyIndex in dataKeys) {
            if (dataKeys[keyIndex] !== "raters" &&
                dataKeys[keyIndex] !== "concernlbls") {
                dataStatus.push(data[dataKeys[keyIndex]].map(({val})=>val));
            }
        }
        for (var xIndex in data.raters) {
            xAxis.push(data.raters[xIndex]);
        }
        for (var yIndex in data.concernlbls) {
            yAxis.push(data.concernlbls[yIndex]);
        }
        for (var dataCopyOuterIndex in dataStatus) {
            for (var dataCopyInnerIndex in dataStatus[dataCopyOuterIndex]) {
                dataStatusWhole.push({
                    concern: +dataCopyOuterIndex + 1,
                    val: parseInt(dataStatus[dataCopyOuterIndex][dataCopyInnerIndex]),
                    pos: parseInt(dataCopyInnerIndex)
                })
            }
        }
        return [xAxis,yAxis,dataStatusWhole];
    }

    drawChart() {
        var [xLabels,yLabels,dataStatusWhole] = this.preprocessData();
        var concernTextLength = 220;
        //Setting props to create SVG
        var numConcerns = yLabels.length;
        var innerWidth = window.innerWidth;
        var width = Math.min(700, innerWidth) - this.state.margin.right - this.state.margin.left;
        var gridSize = Math.floor(width / numConcerns);
        var height = gridSize * (yLabels.length + 2);

        var colorScale = d3.scaleLinear()
            .domain([1, 5])
            .range(["white", "orange"]);

        var xScale = d3.scaleBand(xLabels,[0,gridSize*xLabels.length]).round(true);
        var xAxis = d3.axisBottom(xScale).tickSize(0);
        var yScale = d3.scaleBand(yLabels,[0, gridSize*yLabels.length]).round(true);
        var yAxis = d3.axisLeft(yScale).tickSize(0);

        // Create SVG
        var yaxisSvg = d3.select("#gridchart")
            .append("svg")
            .attr("width", concernTextLength)
            .attr("height", height);
        var svg = d3.select("#gridchart")
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        const dragstarted = () => {draggable.attr("cursor", "grabbing");}
        const dragended = () => {draggable.attr("cursor", "default");}
        const dragged = (event, d) => {
            d3.select("#dragHeatmap")
                .attr("transform", `translate(${Math.min(this.state.margin.left,event.x)},${this.state.margin.top})`);
                // TODO: fix translate x 
        }
        
        var draggable= svg.append("g")
            .attr("transform", "translate(" + this.state.margin.left + "," + this.state.margin.top + ")")
            .attr("id","dragHeatmap")
                .call(d3.drag()
                    .on("start", dragstarted)
                    .on("drag", dragged)
                    .on("end", dragended));


        // generate heatmap
        var g = draggable.append("g")
            .selectAll("surveyData")
            .data(dataStatusWhole)
            .enter().append("rect")
            .attr("x",function (d) { return (d.pos) * gridSize; })
            .attr("y",function (d) { return (d.concern - 1) * gridSize; }).attr("class", "data")
            .attr("width",gridSize-1)
            .attr("height", gridSize-1)
            .style("fill", function (d) {return d.val<0?"#C4C4C4":colorScale(d.val)});

        // generate x and y axis
        yaxisSvg.append("g")
            .attr("transform",`translate(${concernTextLength-5},${this.state.margin.top})`)
            .style("fill","white")
            .call(yAxis);

        draggable.append("g")
            .attr("transform", `translate(0,${gridSize * yLabels.length})`)
            .call(xAxis);
        // TODO: fix translate y

        // adjust font sizes and remove lines of both axis
        d3.selectAll(".tick text").attr("font-size","15");
        d3.selectAll(".domain").remove();



    }


drawLegend(){
    var w = 35, h = 500;

    var legendSvg = d3.select("#gridlegend")
        .append("svg")
        .attr("width", w)
        .attr("height", h)
        .attr("transform", `translate(${this.state.margin.left},${this.state.margin.top})`);  ;

    var legend = legendSvg.append("defs")
        .append("svg:linearGradient")
        .attr("id", "gradient")
        .attr("x1", "0%")
        .attr("y1", "100%")
        .attr("x2", "0%")
        .attr("y2", "0%")
        .attr("spreadMethod", "pad")

    legend.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "white")
        .attr("stop-opacity", 1);

    legend.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", "orange")
        .attr("stop-opacity", 1);

    let lengdTitle = "Most Comfortable"+ "\u00A0".repeat(Math.max(0,(h-260)/4))+"Least Comfortable";

    // gradient legend
    legendSvg.append("rect")
        .attr("width", w)
        .attr("height", h)
        .style("fill", "url(#gradient)")
        .attr("transform", `translate(15,0)`); 

    // title
    legendSvg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -5)
        .attr("x",0 - (h / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(lengdTitle);
}

render(){
    return (
        <div className="surveyboard_editor_div">
            <div id="grid" className="chart_div"> 
                <Modal.Dialog className="chart_modal" backdrop="static" scrollable={true}>
                    <Modal.Body>
                    {
                        <div className="body_div">
                            <div id="gridlegend" className="left-element"/>
                            <div id="gridchart" className="right-element"/>
                        </div>
                    }
                    </Modal.Body>
                </Modal.Dialog>
            </div>
        </div>
    );
  }
}

export default D3GridPlot;