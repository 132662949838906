import React, { Component } from 'react';
import {ReactComponent as ReactLogo} from '../privacy_logo.svg';
import "./Footer.css";


class Footer extends Component {

  render() {

    return (
      <footer className="footer mt-auto py-3">
        <div className="footlogo"><a href="/">  <ReactLogo /> Lean Privacy</a></div> 
        <ul className="listtwo">
          <li>Storyboard Tips</li>
          <li>Survey Tips</li>
          <li>Social</li>
          <li>Contact Us</li>
        </ul>
        
        <ul className="listone">
          <li>How it works?</li>
          <li>Examples</li>
          <li>About us</li>
          <li>FAQ</li>
        </ul>


        {/* <div className="container">
          <span className="text-muted">© CHIMPS Lab 2020.</span>
        </div> */}
      </footer>
    );
  }
}

export default Footer;
