import React, { Component } from 'react';
import '@szhsin/react-menu/dist/index.css';
import "./HomeNav.css";

import {ReactComponent as ReactLogo} from '../privacy_logo.svg';


class HomeNav extends Component {
    constructor(props) {
        super(props);

        this._handleFocus = this._handleTitleFocus.bind(this);
        // this._handleFocusOut = this._handleTitleFocusOut.bind(this);
    }

    _handleTitleFocus(text) {
        console.log('Focused with text: ' + text);
    }

    // _handleTitleFocusOut(text) {
    //     console.log('Left editor with text: ' + text);
    // }


    // render() {
    //     return (
    //         <div className="home_nav_div">
    //             <a href="/">  <ReactLogo />  Lean Privacy</a>
                
    //             <div className="right_link">
    //                 <a href="MISSING">  How It Works</a>
    //             </div>
    //             <div className="right_link">
    //                 <a href="MISSING"> About</a>
    //             </div>
    //         </div>
    //     );
    // }

    render() {
        return (
            <div className="d-flex  align-items-baseline">
                <div className="mr-auto p-2 homelogo"><a href="/">  <ReactLogo /> Lean Privacy</a></div> 
                <div className="homeitem selected">
                    <a href="#"> How It Works</a>
                </div>
                <div className="homeitem">
                    <a href="#"> About</a>
                </div>
                <div className="homeitem">
                    <a href="#"> FAQ</a>
                </div>
            </div>
        );
    }
}

export default HomeNav;
