import React from 'react';
import styled from 'styled-components';
import {REACT_FLOW_CHART } from "@mrblenny/react-flow-chart";


const Outer = styled.div`
  padding: 10px;
  font-size: 14px;
  background: grey;
  cursor: move;
  max-width: 10rem; 
  min-width: 9rem;
  color: white;
  margin: 20px 10px;
  height: 40px;
`


const SidebarCollection = styled.div`
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  padding: 10px;
  margin: 20px 10px;
  height: 40px;
  width: 160px;
  border-color: #008554;
`

const SidebarSharing = styled.div`
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  padding: 10px;
  margin: 20px 10px;
  height: 40px;
  width: 160px;
  border-color: #D14229;
`

const SidebarProcessing = styled.div`
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  padding: 10px;
  margin: 20px 10px;
  height: 40px;
  width: 160px;
  border-color: #FFC627;
`

const SidebarUsage = styled.div`
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  padding: 10px;
  margin: 20px 10px;
  height: 40px;
  width: 160px;
  border-color: #C1C1F1;
`


export const SidebarItem = ({ type, ports, properties }) => {
  switch(type) {
    case "datacollection": {
      return (
        <SidebarCollection
          draggable={true}
          onDragStart={ (event) => {
            event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, ports, properties }))
          } }
        >
          Data collection
        </SidebarCollection>
      );
    }
    case "datasharing": {
      return (
        <SidebarSharing
          draggable={true}
          onDragStart={ (event) => {
            event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, ports, properties }))
          } }
        >
          Data sharing
        </SidebarSharing>
      );
    }
    case "dataprocessing": {
      return (
        <SidebarProcessing
          draggable={true}
          onDragStart={ (event) => {
            event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, ports, properties }))
          } }
        >
          Data processing
        </SidebarProcessing>
      );
    }
    case "datausage": {
      return (
        <SidebarUsage
          draggable={true}
          onDragStart={ (event) => {
            event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, ports, properties }))
          } }
        >
          Data usage
        </SidebarUsage>
      );
    }
    default: 
      return (
        <Outer
          draggable={true}
          onDragStart={ (event) => {
            event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, ports, properties }))
          } }
        >
          {type}
        </Outer>
      );
  }
}

export default SidebarItem;
