import * as React from 'react';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';
// import styled from 'styled-components';
import { cloneDeep } from 'lodash'
import {actions} from "@mrblenny/react-flow-chart";

import BoardNav from '../components/BoardNav';
import ProcedureMenu from '../components/ProcedureMenu';
import StoryboardEditor from '../components/StoryboardEditor';
import SurveyboardEditor from '../components/SurveyboardEditor';
import CardEditor from '../components/CardEditor';
import D3GridPlot from '../components/D3GridPlot';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import {loadBoardById, updateCurChart, saveBoard2Cloud, prepareBoard4Upload, updateCurSurveyChart} from '../actions/actionCreators';
import './board.css'


class Board extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      currExpandedItem:1
    };
  }


  clickPrev = () =>{
    this.setState((state) => ({
        currExpandedItem: state.currExpandedItem - 1
      }));
  }

  clickNext = () =>{
      this.setState((state) => ({
          currExpandedItem: state.currExpandedItem + 1
        }));
  }

  render () { 
    var {boardId, curboard} = this.props;


    let chartStateActions = Object.keys(actions).reduce((obj,key,idx)=>{
      obj[key] = (...args)=>{
          let action = actions[key];
          let newChartTransformer = action(...args);
          let newChart = newChartTransformer(curboard.chart);
          const { dispatch } = this.props;
          dispatch(updateCurChart(cloneDeep(newChart)));
          return newChart;  
      };// var cur_story = {
      return obj;
  },{});

    let surveyChartStateActions = Object.keys(actions).reduce((obj,key,idx)=>{
      obj[key] = (...args)=>{
          let action = actions[key];
          let newChartTransformer = action(...args);
          if(curboard.surveychart){
            let newSurveyChart = newChartTransformer(curboard.surveychart);
            const { dispatch } = this.props;
            dispatch(updateCurSurveyChart(cloneDeep(newSurveyChart)));
            return newSurveyChart;  
          }else{
            return undefined
          }
          
      };// var cur_story = {
      return obj;
    },{});

    const handleTitleFocusOut = (text) => {
      console.log('Updating board title: ' + text);
      // const { dispatch } = this.props;
      curboard["title"] = text;
    }

    const hanldeMenuClick = e => {
      console.log('Menu save clicked: ' + e.value);
      const { dispatch } = this.props;
      switch (e.value) {
        case "save":
          if (curboard["story_type"] === "example") {
            alert("You cannot save modified examples directly. Please duplicate first.")
          } else {
            dispatch(saveBoard2Cloud(boardId, curboard));
          }
          break;
        case "duplicate":
          let new_title = prompt("What would you like the new board to be called?");
          if (new_title == null || new_title == '') {
            return;
          }
          // dispatch(copyBoard(new_title, board));
          let boardId = uuidv4();
          console.log(boardId);
          /* Create a copy of the board */
          var boardcpy = cloneDeep(curboard);
          boardcpy['title'] = new_title;
          boardcpy['_id'] = boardId;
          boardcpy["story_type"] = "test";
          curboard["status"] = "ongoing";
          /* Upload to database */
          axios
            .post(window.BACKEND_ADDRESS+"/boards", prepareBoard4Upload(boardcpy))
            .then(({ data }) => {
              console.log("Created Board = "); 
              console.log(data);
              window.location.href =  boardId;
            });
          break;
        default:
          console.log("unknown event.")
      }
    }

    

    return (
      <React.Fragment>
          { curboard !== null ?
          <div>
            <Helmet>
              <title>{curboard.title}</title>
            </Helmet>
            <ProcedureMenu currExpandedItem={this.state.currExpandedItem} clickPrev={this.clickPrev.bind(this)} clickNext={this.clickNext.bind(this)}></ProcedureMenu>
            <BoardNav boardtitle={curboard.title} onMenuClick={hanldeMenuClick} onTitleChange={handleTitleFocusOut}></BoardNav>
            {(()=>{
                  switch(this.state.currExpandedItem) {
                    case 1:
                      return(
                        <div>
                          <StoryboardEditor chartdata={curboard.chart} stateActions={chartStateActions}></StoryboardEditor>
                          {curboard.chart && curboard.chart.selected.type === "node"? 
                            (<CardEditor chartdata={curboard.chart} stateActions={chartStateActions}></CardEditor>):
                            <div></div>}
                            </div>)
                      case 8:
                        return(<D3GridPlot></D3GridPlot>)
                      default:
                       return (<div><SurveyboardEditor boardid={curboard._id} title={curboard.title} surveyChartData={curboard.surveychart} chartdata={curboard.chart} stateActions={surveyChartStateActions} step={this.state.currExpandedItem}></SurveyboardEditor></div>)
                  }
            })()}
          </div>:
          <div>
            <Helmet>
                <script>alert("Unknown board ID."); 
                </script>
            </Helmet>
          </div>}
      </React.Fragment>)
    };

  componentDidMount = () => {
    const {dispatch, boardId} = this.props;
    dispatch(loadBoardById(boardId));
  }
}


const mapStateToProps = (state, ownProps) => {
  const { boardId } = ownProps.match.params;
  var curboard = state.customizeBoard;
  return {
    boardId: boardId,
    curboard: curboard,
  };
};


export default connect(mapStateToProps)(Board);
