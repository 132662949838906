import React, { Component } from 'react';
import { Menu, MenuItem} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import "./BoardNav.css";
import EditableLabel from 'react-inline-editing';
import {ReactComponent as ReactLogo} from '../privacy_logo.svg';




class BoardNav extends Component {
    constructor(props) {
        super(props);

        this._handleFocus = this._handleTitleFocus.bind(this);
        // this._handleFocusOut = this._handleTitleFocusOut.bind(this);
    }

    _handleTitleFocus(text) {
        console.log('Focused with text: ' + text);
    }

    // _handleTitleFocusOut(text) {
    //     console.log('Left editor with text: ' + text);
    // }


    render() {
        var {boardtitle, onTitleChange, onMenuClick} = this.props;
        return (
            <div className="nav_div">
                <div className="boardlogo"><a href="/">  <ReactLogo /> Lean Privacy</a></div> 
                <Menu menuButton={<button className="menu_item">File</button>}>
                    <MenuItem disabled>New</MenuItem>
                    <MenuItem value="save" onClick={onMenuClick}>Save</MenuItem>
                    <MenuItem value="duplicate" onClick={onMenuClick}>Duplicate</MenuItem>
                    <MenuItem disabled>Download</MenuItem>
                    <MenuItem disabled>Import</MenuItem>
                </Menu>
                <Menu menuButton={<button className="menu_item">Edit</button>}>
                    <MenuItem disabled>Undo</MenuItem>
                    <MenuItem disabled>Redo</MenuItem>
                    <MenuItem disabled>Clear</MenuItem>
                </Menu>
                <Menu menuButton={<button className="menu_item mr-auto">Help</button>}>
                    <MenuItem disabled>Video</MenuItem>
                    <MenuItem disabled>Worksheet</MenuItem>
                    <MenuItem disabled>Examples</MenuItem>
                    <MenuItem disabled>Articles</MenuItem>
                </Menu>
                <div className="mr-auto">
                    {boardtitle?
                    <EditableLabel text={boardtitle}
                                   labelPlaceHolder = "untitled"
                                   labelClassName='titleLable'
                                   inputClassName='titleInput'
                                   inputWidth='300px'
                                   inputHeight='25px'
                                   inputMaxLength={50}
                                   labelFontWeight='normal'
                                   inputFontWeight='normal'
                                   onFocus={this._handleFocus}
                                   onFocusOut={onTitleChange}
                    />:<div></div>}
                </div>
                <div className="righticonbtn">
                    <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.3809" cy="4.47608" r="3.61909" fill="#5F6368"/>
                        <circle cx="15.3809" cy="20.762" r="3.61909" fill="#5F6368"/>
                        <circle cx="3.61909" cy="12.6188" r="3.61909" fill="#5F6368"/>
                        <line x1="13.8493" y1="3.92819" x2="5.70655" y2="10.2616" stroke="#5F6368" strokeWidth="0.904772"/>
                        <line x1="13.3097" y1="20.2259" x2="5.06279" y2="14.3729" stroke="#5F6368" strokeWidth="0.904772"/>
                    </svg>
                </div>

                <div className="righticonbtn">
                    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0H20V23L10 14L0 23V0Z" fill="#5F6368"/>
                    </svg>
                </div>
            </div>
        );
    }
}

export default BoardNav;
