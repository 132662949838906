import React, { Component } from 'react';
import styled from 'styled-components';

import RichTextEditor from './RichTextEditor' 
import './CardEditor.css'

const Message = styled.div`
  margin: 5px;
  padding: 10px;
  line-height: 1.4em;
`



const InfoTxt = styled.div`
  font-size: 8px;
  margin: 0px;
`


const DeleteButton = styled.div`
  padding: 10px 15px;
  background: cornflowerblue;
  color: white;
  border-radius: 3px;
  text-align: center;
  transition: 0.3s ease all;
  cursor: pointer;
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
  }
  &:active {
    background: #5682d2;
  }
`

class CardEditor extends Component {
  constructor(props) {
    super(props);
    this.selectedMeta = this.props.chartdata.selected;
    this.stateActions = this.props.stateActions;
    this.state={
      selectednode: this.props.chartdata["nodes"][this.selectedMeta.id],
      nodetype : this.selectedMeta.type,
      nodeid : this.selectedMeta.id,
    }

    this.handleSummaryChange = this.handleSummaryChange.bind(this);
    this.handleDescChange = this.handleDescChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    console.log("called");
    // console.log(this.state.desc);
    this.initContent = {"summary":this.state.selectednode["properties"]["summary"],
                        "desc":this.state.selectednode["properties"]["desc"]}
  }

  static getDerivedStateFromProps(props, state) {
    var selectedMeta = props.chartdata.selected;
    console.log(selectedMeta);
    if (selectedMeta.type === "link") {
      return state={
        selectednode: null,
        nodetype : null,
        nodeid : null
      }
    }
    var selectedNode = props.chartdata["nodes"][selectedMeta.id];
    console.log("update from pros: " + selectedNode["properties"]["desc"]);
    return state={
      selectednode: selectedNode,
      nodetype : selectedMeta.type,
      nodeid : selectedMeta.id
    }
  }

  handleSummaryChange(event) {
    var tmpnode = this.state.selectednode;
    tmpnode["properties"]["summary"] = event.target.value;
    this.setState({
      selectednode: tmpnode
    });
  }

  handleDescChange(event) {
    var tmpnode = this.state.selectednode;
    tmpnode["properties"]["desc"] = event.target.value;
    this.setState({
      selectednode: tmpnode
    });
  }  

  handleCancel(){
    var tmpnode = this.state.selectednode;
    tmpnode["properties"]["summary"] = this.initContent["summary"];
    tmpnode["properties"]["desc"] = this.initContent["desc"];
    this.setState({
      selectednode: tmpnode
    });
    // not work
    this.props.chartdata.selected.type=undefined;
  }


  render (){
      const nodeToText = {
        'datacollection': "Data Collection",
        'datasharing': "Data Sharing",
        'dataprocessing': "Data Processing",
        'datausage': "Data Usage"
      };
      let currNodeString = nodeToText[this.state.selectednode.type];
      let summaryText = "Write "+ currNodeString + " in Succinct Text";
      let detailText = "Explain " + currNodeString+ " in Detail";
    return (
      <div className="cardeditor">
          {/* <InfoTxt> Node type: {this.state.nodetype}; NodeID: {this.state.nodeid}</InfoTxt> */}
          <div>
              <p className="ml-0 txt_title">{currNodeString}</p>
              <textarea id="txt_node_summary" className="form-control form-rounded pl-1" rows="3" 
                        onChange={this.handleSummaryChange} 
                        value={this.state.selectednode["properties"]["summary"]}
                        placeholder={summaryText}></textarea>
              <textarea id="txt_node_desc" className="form-control form-rounded pl-1" rows="6" 
                        onChange={this.handleDescChange} 
                        value={this.state.selectednode["properties"]["desc"]}
                        placeholder={detailText}></textarea>
          </div>
          <Message>
            <DeleteButton onClick={() => this.stateActions.onDeleteKey({})}>Delete</DeleteButton>
          </Message>
          {/* <RichTextEditor></RichTextEditor> */}
          <button onClick={this.handleCancel}>Cancel</button>
          <button onClick={this.handleSave}>Save</button>
      </div>
      
    )
  }
}

export default CardEditor;


