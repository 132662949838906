import React, { Component } from 'react';
import {FlowChart} from "@mrblenny/react-flow-chart";
import {NodeCustom, NodeInnerCustom} from '../components/Card';

import "./StoryboardEditor.css";

class StoryboardEditor extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {stateActions, chartdata} = this.props;
        return (
            <div className="storyboard_editor_div">
                { chartdata ? <FlowChart chart={chartdata} callbacks={stateActions}
                  Components={{ Node: NodeCustom, NodeInner: NodeInnerCustom}} />:
                <div></div>
            }
            </div>
        );
    }
}

export default StoryboardEditor;
