// https://codepen.io/SeanMcP/pen/marZrx
import React from 'react';

import styled from 'styled-components'


const PIOCardCollection = styled.div`
  position: absolute;
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  width: 250px;
  height: 110px;
  border-color: #008554;
`

const PIOCardSharing = styled.div`
  position: absolute;
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  width: 250px;
  height: 110px;
  border-color: #D14229;
`

const PIOCardProcessing = styled.div`
  position: absolute;
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  width: 250px;
  height: 110px;
  border-color: #FFC627;
`

const PIOCardUsage = styled.div`
  position: absolute;
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  width: 250px;
  height: 110px;
  border-color: #C1C1F1;
`

const PIOCardMsgCategory = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-top: 5px;
  margin-left: 8px;
  float: left;
`

const PIOCardMsgTxt = styled.div`
  float: left;
  margin-left: 12px;
  width: 210px;
  height: 80px;
  font-size: 0.8rem;
  display: -webkit-flex; /* Safari */
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  display: flex;  
  flex-wrap: wrap;
  overflow-wrap: break-word;
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  white-space: pre-wrap;       /* css-3 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  white-space: normal;
  overflow:hidden;
  text-align: left;
`

// const PIOCardBtn = styled.div`
//   background: transparent;
//   border: none;
//   font-family: inherit;
//   font-size: inherit;
//   margin-left: 1rem;
//   opacity: 0.5;
//   padding: 0;
// `



/**
 * Create the custom component,
 * Make sure it has the same prop signature
 * You'll need to add {...otherProps} so the event listeners are added to your component
 */
export const NodeCustom = React.forwardRef(({ node, children, ...otherProps }, ref) => {
  switch(node.type) {
    case 'datacollection':
      return (
        <PIOCardCollection ref={ref} {...otherProps}>
          {children}
        </PIOCardCollection>
      );
    case 'datasharing': 
      return (
        <PIOCardSharing ref={ref} {...otherProps}>
          {children}
        </PIOCardSharing>
      );
    case 'dataprocessing':
      return (
        <PIOCardProcessing ref={ref} {...otherProps}>
          {children}
        </PIOCardProcessing>
      );
    case 'datausage': 
      return (
        <PIOCardUsage ref={ref} {...otherProps}>
          {children}
        </PIOCardUsage>
      );
    default:
      return (
        <div ref={ref} {...otherProps}>
          {children}
        </div>
      )
  }
})


  /**
 * Create the custom component,
 * Make sure it has the same prop signature
 */
export const NodeInnerCustom = ({ node, config } ) => {
    switch(node.type) {
      case 'datacollection':
        return (
          <div>
            <PIOCardMsgCategory>Data collection</PIOCardMsgCategory>
            <PIOCardMsgTxt>{node["properties"]["summary"]}</PIOCardMsgTxt>
          </div>
        );
      case 'datasharing': 
        return (
          <div>
            <PIOCardMsgCategory>Data sharing</PIOCardMsgCategory>
            <PIOCardMsgTxt>{node["properties"]["summary"]}</PIOCardMsgTxt>
          </div>
        );
      case 'dataprocessing':
        return (
          <div>
            <PIOCardMsgCategory>Data processing</PIOCardMsgCategory>
            <PIOCardMsgTxt>{node["properties"]["summary"]}</PIOCardMsgTxt>
          </div>
        );
      case 'datausage': 
        return (
          <div>
            <PIOCardMsgCategory>Data usage</PIOCardMsgCategory>
            <PIOCardMsgTxt>{node["properties"]["summary"]}</PIOCardMsgTxt>
          </div>
        );
      default:
        return (
          <div>
            <PIOCardMsgCategory>Data collection</PIOCardMsgCategory>
            <PIOCardMsgTxt>{node["properties"]["summary"]}</PIOCardMsgTxt>
          </div>
        )
    }
  }