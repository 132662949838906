import * as React from 'react';
import SidebarItem from './SidebarItem';


export const StoryboardMenu = ({ type, ports, properties }) => {
    return (
        <div>
            <SidebarItem
                  type="datacollection"
                  ports={ {
                    port1: { id: 'port1', type: 'left', properties: { custom: 'property', }, },
                    port2: { id: 'port2', type: 'right', properties: { custom: 'property', }, },
                  } }
                  properties={ { custom: 'property', }}
                />
            <SidebarItem
                  type="datasharing"
                  ports={ {
                    port1: { id: 'port1', type: 'left', properties: { custom: 'property', }, },
                    port2: { id: 'port2', type: 'right', properties: { custom: 'property', }, },
                  } }
                  properties={ { custom: 'property', }}
                />
            <SidebarItem
                  type="dataprocessing"
                  ports={ {
                    port1: { id: 'port1', type: 'left', properties: { custom: 'property', }, },
                    port2: { id: 'port2', type: 'right', properties: { custom: 'property', }, },
                  } }
                  properties={ { custom: 'property', }}
                />

            <SidebarItem
                  type="datausage"
                  ports={ {
                    port1: { id: 'port1', type: 'left', properties: { custom: 'property', }, },
                    port2: { id: 'port2', type: 'right', properties: { custom: 'property', }, },
                  } }
                  properties={ { custom: 'property', }}
                />
        </div>
    );
  }

  export default StoryboardMenu;
