import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { Switch, Route, Redirect } from "react-router-dom";
import appRoutes from "./shared/appRoutes";
import { BrowserRouter as Router } from "react-router-dom";

import HomePage from "./containers/home";
// import OldBoardPage from "./containers/oldboard";
import BoardPage from "./containers/board";


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
            <Route exact path={appRoutes.home} component={HomePage}>
              {/* <HomePage/> */}
            </Route>
            {/* <Route path={appRoutes.oldboard} component={OldBoardPage}> */}
              {/* <BoardPage /> */}
            {/* </Route> */}
            <Route path={appRoutes.board} component={BoardPage}>
            </Route>
            <Redirect to={appRoutes.home} />
          </Switch>
      </Router>
    </div>
  );
}

export default App;