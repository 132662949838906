import axios from 'axios';
import {ObjectId} from 'bson';
import { cloneDeep } from 'lodash'
// import {EMPTY_CHART_DATA} from '../utils/constants'

// import * as util from 'util' // has no default export
// import { inspect } from 'util' // or directly

/* eslint-disable no-console */

export const loadExampleBoards = (boardsDict) => dispatch => {
  console.log(boardsDict);
  dispatch({
    type: "LOAD_EXAMPLE_BOARDS",
    payload: boardsDict
  });
};


export const loadBoardById = (boardId) => dispatch => {
  axios
  .get(window.BACKEND_ADDRESS + `/board/` + boardId)
  .then(response  => {
      const curboard = response.data;
      console.log(curboard);
      if (curboard) {
        dispatch({
          type: "LOAD_BOARD",
          payload: curboard
        });
      } else {
        dispatch({
          type: "FIND_NO_BOARD"
        });
      }
    }
  );
};

export const updateCurChart = (chart) => dispatch => {  
  dispatch({
    type: "UPDATE_BOARD_CHART",
    payload: {chart}
});
};

export const updateCurSurveyChart = (surveychart) => dispatch => {  
  dispatch({
    type: "UPDATE_BOARD_SURVEYCHART",
    payload: {surveychart}
});
};


export const saveBoard2Cloud = (boardId, curboard) => dispatch => {  
  // dispatch({
  //   type: "SAVE_BOARD2CLOUD",
  //   payload: {boardId, curboard}
  // });
  // TODO: Add error handling.
  // Write the chart data update into the data field.
  const boardcpy = prepareBoard4Upload(curboard);
  axios
  .post(window.BACKEND_ADDRESS + "/board/" + boardId, boardcpy)
  .then(response  => {
    console.log(response);
    // alert("sent");
    }
  );
};

// export const copyBoard = (boardTitle, board) => dispatch => {
//   var boardId = new ObjectId();
//   console.log(boardId.toHexString());
//   /* Create a copy of the board */
//   var boardcpy = cloneDeep(board);
//   boardcpy['title'] = boardTitle;
//   boardcpy['_id'] = boardId;
  
//   /* Update internal state */
//   dispatch({
//     type: "COPY_BOARD",
//     payload: {boardId , boardcpy}
//   });

//   /* Upload to database */
//   axios
//     .post(window.BACKEND_ADDRESS+"/boards", prepareBoard4Upload(boardcpy))
//     .then(({ data }) => {console.log("Created Board = "); console.log(data)});
// }


/* HELPERS */

/* Serialize chart data (remove circularity!) */
export const serializeChartData = (chartData) => {
  var uploadcpy = cloneDeep(chartData);
  console.log(uploadcpy)
  
  for(var nodeid in uploadcpy["nodes"]) {
    console.log(nodeid)
    if(uploadcpy["nodes"][nodeid]["position"] === undefined){
      var tmp = uploadcpy["nodes"]
      delete tmp[nodeid]
    }
    else if("node" in uploadcpy["nodes"][nodeid]["position"]) {
      console.log("cleaned");
      tmp = uploadcpy["nodes"][nodeid]["position"];
      delete tmp["node"];
    }
  }

  if("node" in uploadcpy["offset"]) {
    console.log("cleaned");
    tmp = uploadcpy["offset"];
    delete tmp["node"];
  }
  uploadcpy["selected"] = {};
  uploadcpy["hovered"] = {};
  return JSON.stringify(uploadcpy);
};

/* Make a copy, serialize board data, delete self referential data structures 
    (artifacts of FlowChart Library) */
export const prepareBoard4Upload = (board) => {
  console.log(board)
  var boardcpy = cloneDeep(board);
  boardcpy.data = serializeChartData(board.chart);
  delete boardcpy['chart'];
  return boardcpy;
}

// ==========


export const addBoard = (boardTitle) => dispatch => {
  // var objectId = new ObjectID();
  // const boardId = shortid.generate();
  var boardId = new ObjectId();
  console.log(boardId.toHexString());
  dispatch({
    type: "ADD_BOARD",
    payload: { boardTitle, boardId }
  });
  var cur_story = {};
  cur_story["_id"] = boardId;
  cur_story["title"] = boardTitle;
  axios
    .post(window.BACKEND_ADDRESS+"/boards", cur_story)
    .then(({ data }) => console.log(data));
};

export const deleteBoard = (boardId) => dispatch => {
  dispatch({
    type: "DELETE_BOARD",
    payload: { boardId }
  });
  axios
    .delete(window.BACKEND_ADDRESS+"/boards", {data: {boardId}})
    .then(({ data }) => console.log(data));
};

export const loadBoards = (boardsDict) => dispatch => {
  console.log(boardsDict);
  dispatch({
    type: "LOAD_BOARDS",
    payload: boardsDict
  });
};

// export const updateBoard = (boardId, board) => dispatch => {  
//   dispatch({
//     type: "UPDATE_BOARD",
//     payload: { boardId, board }
//   });

//   axios
//     .post(window.BACKEND_ADDRESS+"/boards/" + boardId, uploadReadyBoard(board))
//     .then(({ data }) => {console.log(data); alert("uploaded!")})
//     .catch(function (error) {
//       console.log(error);
//       alert("something went wrong.")
//     });
// };

// export const copyBoard = (boardTitle, board) => dispatch => {
//   var boardId = new ObjectId();
//   console.log(boardId.toHexString());
//   /* Create a copy of the board */
//   var boardcpy = cloneDeep(board);
//   boardcpy['title'] = boardTitle;
//   boardcpy['_id'] = boardId;
  
//   /* Update internal state */
//   dispatch({
//     type: "COPY_BOARD",
//     payload: {boardId , boardcpy}
//   });

//   /* Upload to database */
//   axios
//     .post(window.BACKEND_ADDRESS+"/boards", uploadReadyBoard(boardcpy))
//     .then(({ data }) => {console.log("Created Board = "); console.log(data)});
// }


export const updateChart = (boardId, chart) => dispatch => {  
  dispatch({
    type: "UPDATE_CHART",
    payload: {boardId, chart}
  });
};

export const updateSurveyChart = (boardId, surveyChart) => dispatch => {  
  dispatch({
    type: "UPDATE_SURVEY_CHART",
    payload: {boardId, surveyChart}
  });
};



// export const createBoard = (boardId) => dispatch => {
//   // var objectId = new ObjectID();
//   // const boardId = shortid.generate();
//   // console.log(boardId.toHexString());
//   const boardTitle = "Untitled";
//   dispatch({
//     type: "ADD_NEW_BOARD",
//     payload: { boardTitle, boardId }
//   });
//   var curboard = {};
//   curboard["_id"] = boardId;
//   curboard["title"] = boardTitle;
//   curboard["data"] = EMPTY_CHART_DATA;
//   curboard["story_type"] = "test";
//   axios
//     .post(window.BACKEND_ADDRESS+"/boards", curboard)
//     .then(({ data }) => console.log(data));
// };