import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import {loadExampleBoards} from '../actions/actionCreators';
import {EMPTY_CHART_DATA} from '../utils/constants'

import Footer from '../components/Footer'
import styled from 'styled-components';
import HomeNav from '../components/HomeNav';
import './home.css'


const StyledHome = styled.div`
  max-width: 1440px; 
  min-width: 1200px;
  margin: 0 auto; 
`



const StyledCustom1 = styled.div`
    width: 72px;
    height: 72px;
    background: rgba(0, 134, 84, 0.8);
    border-radius: 10px;
    font-size: 45px;
    color: #494747;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
`

const StyledCustom2 = styled.div`
    width: 72px;
    height: 72px;
    background: rgba(210, 66, 40, 0.8);
    border-radius: 10px;
    font-size: 45px;
    color: #494747;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
`

const StyledCustom3 = styled.div`
    width: 72px;
    height: 72px;
    background: rgba(253, 213, 91, 0.8);
    border-radius: 10px;
    font-size: 45px;
    color: #494747;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
`

const StyledExplaination = styled.div`
    width: 200px;
    text-align: left;
    margin-left: 15px;
`

const List = styled.div`
  margin-top: 1rem;
  padding: 12px 12px 12px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  @media (max-width: 768px) {
    width: 320px;
    font-size: 14px;
  }
`

const StyledLink = styled(Link)`
  &:hover,
  &:focus,
  &:active {
    opacity: 0.85;
  }
`

const StyledStoryItem = styled.div`
  width: 200px;
  height: 100px;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  justify-content: space-between;
  font-weight: 500;
  // border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: 12px;
  margin: 12px;
  align-self: flex-end;
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

`


class Home extends Component<Props> {
  // constructor(props) {
  //   super(props);
  // }

  createBoard = (event) => {
    let newid = uuidv4();
    const boardTitle = "Untitled";
    var curboard = {};
    curboard["_id"] = newid;
    curboard["title"] = boardTitle;
    curboard["data"] = EMPTY_CHART_DATA;
    curboard["story_type"] = "test";
    curboard["status"] = "ongoing";

    axios
    .post(window.BACKEND_ADDRESS + "/boards", curboard)
    .then(response  => {
      console.log(response);
      // alert("sent");
      window.location.href = 'board/' + newid;
      }
    );
  };

  render = () => {
    const { exampleboards } = this.props;
    console.log(exampleboards);
    return (
        <StyledHome>
          <Helmet>
            <title>Lean Privacy Review</title>
          </Helmet>
          <HomeNav/>
          <div className="mt-1 carousel">
              <img height="400px" className="thumbnail" src="resources/webeditor-thumb.png"></img>
              <p className="projecttitle">Lean Privacy Review</p>
              <p className="projectdesc">Collecting users’ privacy concerns about your data practice at a low cost</p>
              <button type="button" onClick={() => this.createBoard()}  className="btn btn-primary btn_new_proj">Create a storyboard</button>
          </div>
          <div className="leancontainer mt-2">
            <div className="row mt-3">
              <div className="col-sm-12 text-left">
                <p className="howitworks">How it works?</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-4 d-flex align-items-end justify-content-end">
                <StyledCustom1>1</StyledCustom1> <StyledExplaination >Create a Privacy Storyboard</StyledExplaination>
              </div>
              <div className="col-sm-4  d-flex align-items-end justify-content-center">
                <StyledCustom2>2</StyledCustom2> <StyledExplaination >Inspect Data Actions via Crowd Workers</StyledExplaination>
              </div>
              <div className="col-sm-4 d-flex align-items-end justify-content-start">
                <StyledCustom3>3</StyledCustom3> <StyledExplaination >Analyze the Privacy Concerns</StyledExplaination>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-12">
                  <iframe width="1000" height="550"  title="tutorial"  src="https://www.youtube.com/embed/2F8N6Nb1DJA" frameBorder="0.5" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
          </div>
          <div className="storyexamplecontainer">
            <div className="leancontainer">
            <p className="storyexamples">Storyboard Examples</p>
          <div className="row">
              <div className="col-sm-12 text-left">
                
              <List>
              {exampleboards.map(board => (
                  <StyledStoryItem key={`row-${board._id}`}>
                    <StyledLink
                        to={`board/${board._id}`}>
                      {board.title}
                    </StyledLink>
                  </StyledStoryItem>
              ))}
            </List>
            </div>
            </div>

          </div>
          </div>
          <Footer></Footer>
        </StyledHome>
    );
  };

  componentDidMount = () => {
    const {dispatch} = this.props;

    axios
        .get(window.BACKEND_ADDRESS + `/boards/example`)
        .then(response  => {
              const boardArray = response.data;
              dispatch(loadExampleBoards(boardArray));
            }
        );
  }
}

const mapStateToProps = state => ({
  exampleboards: Object.values(state.exampleboards),
  // localboards: [] TODO: 
});

export default connect(mapStateToProps)(Home);
