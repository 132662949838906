// import { cloneDeep, mapValues } from 'lodash';
// import { changeset } from 'vega';
// import { ObjectId } from 'bson';
// import { v4 as uuidv4 } from 'uuid';
// import { combineReducers } from 'redux'


const exampleboards = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_EXAMPLE_BOARDS": {
      console.log("example boards loaded." );
      const boardArray  = action.payload;
      let boardDict = {};
      // const empty_chart_data = '{"offset":{"x":0,"y":0},"nodes":{},"links":{},"selected":{},"hovered":{}}';
      for (let board of boardArray) {
        boardDict[board._id] = board
      }      
      return boardDict;
    }
    default:
      return state;
  }
}

const customizeBoard = (state = {}, action) => {
  switch (action.type) {
    case "ADD_NEW_BOARD": {
      console.log("added new board");
      return {};
    } 

    case "LOAD_BOARD": {
      const curboard = action.payload;
      if(curboard) {
        curboard.chart = JSON.parse(curboard.data);
        console.log(curboard.chart);
        return curboard;
      } else {
        return {};
      }
    }

    case "FIND_NO_BOARD": {
      return null;
    }

    case "UPDATE_BOARD_CHART": {
      const {chart} = action.payload;
      return {
        ...state,
        "chart": chart          
      };
    }

    case "UPDATE_BOARD_SURVEYCHART": {
      const {surveychart} = action.payload;
      return {
        ...state,
        "surveychart": surveychart          
      };
    }

    default:
      return state;
  }
}




const boardsById = (state = {}, action) => {
  switch (action.type) {

    case "LOAD_BOARDS": {
      console.log("boards loaded." );
      let boardDict = {}
      const empty_chart_data = '{"offset":{"x":0,"y":0},"nodes":{},"links":{},"selected":{},"hovered":{}}';
      for (let board of action.payload) {
        /* Put empty board in chart if its undefined currently */
        if (board.data !== undefined) {
          board.chart = JSON.parse(board.data);
          console.log("Found empty board. Check!")
        } else {
          board.chart = JSON.parse(empty_chart_data);
        }
        /* Only fill in surveychart if it exists and is not empty, 
            decoupling will be used if its empty/doesn't exist */
        if (board.surveyData !== undefined && board.surveyData !== empty_chart_data) {
          board.surveyChart = JSON.parse(board.surveyData);
        } 

        boardDict[board._id] = board
      }      
      return boardDict;
    }
    
    case "ADD_BOARD": {
      const { boardId, boardTitle } = action.payload;
      const empty_chart_data = '{"offset":{"x":0,"y":0},"nodes":{},"links":{},"selected":{},"hovered":{}}';
      return {
        ...state,
        [boardId]: { _id: boardId, title: boardTitle, data: empty_chart_data, surveyData: empty_chart_data, chart: JSON.parse(empty_chart_data),
                        surveyChart: JSON.parse(empty_chart_data)}
      };
    }

    case "DELETE_BOARD": {
      const { boardId } = action.payload;
      const { [boardId]: deletedBoard, ...restOfBoards } = state;
      return restOfBoards;
    }

    case "UPDATE_BOARD": {
      const { boardId, board } = action.payload;
      return {
        ...state,
        [boardId]: board
      };
    }

    case "UPDATE_CHART": {
      const { boardId, chart } = action.payload;
      return {
        ...state,
        [boardId]: {
          ...state[boardId],
          "chart": chart
        }
      };
    }

    case "UPDATE_SURVEY_CHART": {
      const { boardId, surveychart } = action.payload;
      return {
        ...state,
        [boardId]: {
          ...state[boardId],
          "surveychart": surveychart
        }
      };
    }

    // case "COPY_BOARD": {
    //   const {boardId, board} = action.payload;
    //   return {
    //     ...state,
    //     [boardId]: board
    //   };
    // }
    default:
      return state;
  }
};



export default {exampleboards, boardsById, customizeBoard}
