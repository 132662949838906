import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducers from './reducers/reducers';

import 'bootstrap/dist/css/bootstrap.css';

// window.BACKEND_ADDRESS = "http://127.0.0.1:3001";
window.BACKEND_ADDRESS = "http://3.210.81.13:3001";
// window.BACKEND_ADDRESS = "https://privacyio-backend.herokuapp.com"

const preloadedState = window.PRELOADED_STATE;
delete window.PRELOADED_STATE;


const store = createStore(
  combineReducers(reducers),
  preloadedState,
  composeWithDevTools(applyMiddleware(thunk))
);


const app = (
    <Provider store={store}>
         <App />
    </Provider>
  );

// ReactDOM.hydrate(app, document.getElementById('root'));
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
