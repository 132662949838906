import React, { Component } from 'react';
import { Accordion, AccordionItem } from 'react-sanfona';
import {StoryboardMenu} from './StoryboardMenu';
import "./ProcedureMenu.css";

class ProcedureMenu extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div className="left_sidebar_div">
                <div className="d-flex justify-content-around mt-3">
                    <button className="button step_button" disabled={this.props.currExpandedItem<2} onClick={this.props.clickPrev}>Previous</button>
                    <button className="button step_button" disabled={this.props.currExpandedItem>7} onClick={this.props.clickNext}>Next</button>
                </div>
                <Accordion className="mt-4 collapsible_menu">
                    <AccordionItem title="Create a storyboard" className="procedure mt-2 clickable" expanded={this.props.currExpandedItem === 1}>
                        <StoryboardMenu></StoryboardMenu>
                    </AccordionItem>
                    {/* <AccordionItem title="Generate surveys" className="procedure mt-2" expanded={[2,3,4].includes(this.props.currExpandedItem)}> */}
                        {/* <Accordion className="mt-2"> */}
                    <AccordionItem title="Decouple storyboards" className="procedure mt-2" expanded={this.props.currExpandedItem === 2}/>
                    <AccordionItem title="Preview surveys" className="procedure mt-2" expanded={this.props.currExpandedItem === 3}/>
                    <AccordionItem title="Export surveys" className="procedure mt-2" expanded={this.props.currExpandedItem === 4}/>
                        {/* </Accordion> */}
                    {/* </AccordionItem> */}
                    <AccordionItem title="Collect survey responses" className="procedure mt-2" expanded={this.props.currExpandedItem === 5}>
                        {/* <div>abcd</div> */}
                    </AccordionItem>
                    <AccordionItem title="Distribute labeling tasks" className="procedure mt-2" expanded={this.props.currExpandedItem === 6}>
                        {/* <div>abcd</div> */}
                    </AccordionItem>
                    <AccordionItem title="Import data" className="procedure mt-2" expanded={this.props.currExpandedItem === 7}>
                        {/* <div>abcd</div> */}
                    </AccordionItem>
                    <AccordionItem title="Analyze privacy concerns" className="procedure mt-2" expanded={this.props.currExpandedItem === 8}>
                        {/* <div>abcd</div> */}
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}

export default ProcedureMenu;
